import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useRef, useState } from 'react'
import { Link as GatsbyLink, graphql, PageProps, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { Image } from '../component/Images'
import { Box, Button, Hidden, Typography } from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
// import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import SortIcon from '@material-ui/icons/Sort'
import { Layout } from '../component/Layout'
import TeamSidebar from '../component/page_component/agency/team/TeamSidebar'
import { Avatar } from '../component/Avatar'
import AgentDialog from '../component/page_component/agency/team/AgentDialog'
import MobileSidebar from '../component/page_component/agency/team/mobileSidebar'
import { CustomButton } from '../component/CustomButton'
import { Logo } from '../component/topBar/logo'
import world_map from '../images/Map_Monde_Kretz.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '70px 0em 0em 0em',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'md')]: {},
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '1em 1em',
      // marginTop: '70px',
      zIndex: 1102,
      backgroundColor: '#fff',
    },
  },
  headerSection: {
    flexBasis: '100%',
    justifyContent: 'space-between',
    paddingInline: '2rem',
    [theme.breakpoints.between('xs', 'md')]: {
      flexBasis: 'unset',
      paddingInline: '0 2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingInline: '0 0rem',
      display: 'flex',
      alignItems: 'center',
    },
  },
  teamTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(80),
    textAlign: 'left',
    textTransform: 'uppercase',
    display: 'flex',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(80),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },
  },
  sidebarBtn: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  returnBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textTransform: 'uppercase',
    fontFamily: `'Gotham book', serif`,
    fontSize: '12px',
    fontWeight: 700,
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
    [theme.breakpoints.between('xs', 'sm')]: {
      textTransform: 'uppercase',
      fontFamily: `'Gotham Medium', serif`,
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '13.26px',
      padding: '20px 0',
    },
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1em',
    borderTop: '1px solid #E7E7E7',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  content: {
    flexGrow: 1,
    flex: 1,
  },
  agentList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // paddingTop: '1em',
  },
  sidebarContainer: {
    flexShrink: 0,
    borderRight: '1px solid #eee',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  filtreparPaysCTA: {
    fontFamily: `'Gotham Medium', serif`,
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: '#1d1d1b',
  },
}))

export const AgencyTeam: React.FC<PageProps> = (props) => {
  const { location } = props
  const intl = useIntl()
  const classes = useStyles()
  const [agents, setAgents] = useState<string[] | null>(null)
  const [selectedAgent, setSelectedAgent] = useState<any | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [selectedCityName, setSelectedCityName] = useState('')

  const smallArray = [
    { Ordre: 1, Prénom: 'Olivier', Nom: 'Kretz', Description: 'Olivier Kretz - Fondateur et CEO' },
    {
      Ordre: 2,
      Prénom: 'Sandrine',
      Nom: 'Kretz',
      Description: 'Sandrine Kretz - Directrice associée',
    },

    { Ordre: 3, Prénom: 'Martin', Nom: 'Kretz', Description: 'Martin Kretz - Directeur associé' },
    {
      Ordre: 4,
      Prénom: 'Valentin',
      Nom: 'Kretz',
      Description: 'Valentin Kretz - Directeur associé',
    },
    {
      Ordre: 5,
      Prénom: 'Louis',
      Nom: 'Kretz',
      Description: 'Louis Kretz - Co-directeur Kretz spain',
    },
    {
      Ordre: 6,
      Prénom: 'Alexandre',
      Nom: 'Bruneau',
      Description: 'Alexandre Bruneau - Directeur Général',
    },

    {
      Ordre: 7,
      Prénom: 'Adriana',
      Nom: 'Pinos',
      Description: 'Adriana Pinos -  Co-directrice Kretz Spain',
    },
    {
      Ordre: 8,
      Prénom: 'Camille',
      Nom: 'Richard',
      Description: 'Camille Richard - Coordinateur Commerciale Kretz Spain',
    },
    { Ordre: 9, Prénom: 'Yann', Nom: 'Miossec', Description: 'Yann Miossec - Directeur Financier' },
    {
      Ordre: 10,
      Prénom: 'Victor',
      Nom: 'Choppe',
      Description: 'Victor Choppe - Responsable Animation Commerciale',
    },
    {
      Ordre: 11,
      Prénom: 'Virgile',
      Nom: 'Voiseau',
      Description: 'Virgile Voiseau - Responsable Production de contenu',
    },
    {
      Ordre: 12,
      Prénom: 'Lisa',
      Nom: 'Azzarone',
      Description: 'Lisa Azzarone - Responsable Communication',
    },
    {
      Ordre: 13,
      Prénom: 'Mathilde',
      Nom: 'Mas',
      Description: 'Mathilde Mas - Chargée de communication',
    },
    {
      Ordre: 14,
      Prénom: 'Melgwen',
      Nom: 'Dosseh-Martenot',
      Description: 'Melgwen Dosseh-Martenot - Responsable Coordination Commerciale',
    },
    {
      Ordre: 15,
      Prénom: 'Amandine',
      Nom: 'Landois',
      Description: 'Amandine Landois - Coordinatrice Commerciale',
    },
    {
      Ordre: 16,
      Prénom: 'Victoire',
      Nom: "Parra d'Andert",
      Description: 'Victoire Parra d’Andert - Coordinatrice Commerciale',
    },
    {
      Ordre: 17,
      Prénom: 'Raphaël',
      Nom: 'Kretz',
      Description: '',
    },

    {
      Ordre: 18,
      Prénom: 'Begoña',
      Nom: 'Vázquez Orozco',
      Description: '',
    },
    {
      Ordre: 19,
      Prénom: 'Jorge',
      Nom: 'De Andrés',
      Description: '',
    },
    {
      Ordre: 20,
      Prénom: 'Loreto',
      Nom: 'Sierra Garcia',
      Description: '',
    },
    {
      Ordre: 21,
      Prénom: 'Lucía',
      Nom: 'González Casal',
      Description: '',
    },
    {
      Ordre: 22,
      Prénom: 'Nicolle',
      Nom: 'Balanica',
      Description: '',
    },
    {
      Ordre: 23,
      Prénom: 'Carolina',
      Nom: 'Villarroel',
      Description: '',
    },
    {
      Ordre: 24,
      Prénom: 'Aleksandra',
      Nom: 'Collado',
      Description: '',
    },
    {
      Ordre: 25,
      Prénom: 'Sophie',
      Nom: 'Corvaisier',
      Description: '',
    },
    {
      Ordre: 26,
      Prénom: 'Youcef',
      Nom: 'Rahal',
      Description: '',
    },
    {
      Ordre: 27,
      Prénom: 'Tamani',
      Nom: 'Sadoum',
      Description: '',
    },
    {
      Ordre: 28,
      Prénom: 'Guilhem',
      Nom: 'Gauran',
      Description: '',
    },
    {
      Ordre: 29,
      Prénom: 'Aline',
      Nom: 'Arnould',
      Description: '',
    },
    {
      Ordre: 30,
      Prénom: 'Daphné',
      Nom: 'Chaouat',
      Description: '',
    },
  ]
  // Create a ref for the agents container
  const agentsContainerRef = useRef<HTMLDivElement | null>(null)

  const {
    allTeam: { edges },
  } = useStaticQuery(graphql`
    query MyQuery {
      allTeam {
        edges {
          node {
            id
            lname
            fname
            phone
            email
            photo {
              portraitSquare
            }
            destination

            descriptionEn
            descriptionEs
            descriptionFr
            descriptionPt

            titreEn
            titreEs
            titreFr
            titrePt
          }
        }
      }
    }
  `)

  const orderedCountries = ['FR', 'ES', 'US', 'MC', 'BL', 'GR', 'BR', 'MA']

  const transformData = (edges: any[]) => {
    // Create a lookup map from smallArray
    const orderMap: Record<string, number> = smallArray.reduce((acc: Record<string, number>, person) => {
      acc[`${person.Prénom?.toLowerCase()} ${person.Nom?.toLowerCase()}`] = person.Ordre
      return acc
    }, {})

    const grouped = edges.reduce((acc: Record<string, { regions: Record<string, any[]>, cities: Record<string, any[]> }>, person: any) => {
      const parts = person.node.destination.split('/')
      const [country, region = 'all', city = 'all'] = parts

      if (!orderedCountries.includes(country)) return acc

      if (!acc[country]) acc[country] = { regions: {}, cities: {} }
      if (!acc[country].regions[region]) acc[country].regions[region] = []
      if (!acc[country].cities[city]) acc[country].cities[city] = []

      acc[country].cities[city].push(person)
      // Add person to "all" only if they're not already under a specific city
      if (city !== 'all') {
        if (!acc[country].cities['all']) acc[country].cities['all'] = []
        acc[country].cities['all'].push(person)
      }

      acc[country].regions[region].push(person)

      return acc
    }, {})

    return Object.entries(grouped)
      .sort(([a], [b]) => {
        const indexA = orderedCountries.indexOf(a)
        const indexB = orderedCountries.indexOf(b)

        // Handle missing countries (place them at the end)
        return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB)
      })
      .map(([country, data]) => ({
        country,
        regions: Object.entries(data.regions).map(([region, agents]) => ({
          region,
          agents: agents.sort((a: any, b: any) => {
            const orderA =
              orderMap[`${a.node.fname?.toLowerCase()} ${a.node.lname?.toLowerCase()}`] || Infinity
            const orderB =
              orderMap[`${b.node.fname?.toLowerCase()} ${b.node.lname?.toLowerCase()}`] || Infinity

            return orderA - orderB
          }),
        })),
        cities: Object.entries(data.cities).map(([city, agents]) => ({
          city,
          agents: agents.sort((a: any, b: any) => {
            const orderA =
              orderMap[`${a.node.fname?.toLowerCase()} ${a.node.lname?.toLowerCase()}`] || Infinity
            const orderB =
              orderMap[`${b.node.fname?.toLowerCase()} ${b.node.lname?.toLowerCase()}`] || Infinity

            return orderA - orderB
          }),
        })),
      }))
  }

  const handleCitySelect = (city: any) => {
    setSelectedCityName(city.name)
    setAgents(city.agents)
    // Scroll to top of agents container
    if (agentsContainerRef.current) {
      agentsContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
  const handleAgentSelect = (agent: any) => {
    setSelectedAgent(agent)
    setDialogOpen(true)
  }
  const handleCloseDialog = () => {
    setDialogOpen(false)
    setSelectedAgent(null)
  }
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev)
  }

  return (
    <Layout
      showFlotingMenu={false}
      maxWidth={false}
      location={location}
      title={intl.formatMessage({ id: 'Kretz', defaultMessage: 'Kretz' })}
    >
      <Grid container className={classes.root} ref={agentsContainerRef}>
        <Grid item container xs={12} className={classes.headerSection}>
          {/* web version */}
          <Hidden smDown>
            <Grid item xs={6} className={classes.teamTitle}>
              {intl.formatMessage({ id: 'link.team' })}
            </Grid>

            <Grid item xs={6} className={classes.returnBtn}>
              {' '}
              <GatsbyLink to={`/${intl.locale}/agency`} color="inherit" className={classes.link}>
                <KeyboardBackspaceIcon /> {intl.formatMessage({ id: 'back' })}
              </GatsbyLink>
            </Grid>
          </Hidden>
          {/* mobile version */}
          <Hidden smUp>
            <Grid item container xs={12} className={classes.headerSection}>
              <Grid item xs={3}>
                {/* <CustomButton
                  icon={() => <MenuIcon />}
                  borderless
                  iconPosition="end"
                  displayIcon
                  variant=""
                  onClick={toggleSidebar}
                /> */}
                <GatsbyLink to={`/${intl.locale}/agency`} color="inherit" className={classes.link}>
                  <CloseIcon />
                </GatsbyLink>
              </Grid>
              <Grid item xs={6} alignItems="center" justifyContent="center">
                <Logo />
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </Hidden>
        </Grid>

        <Grid item container xs={12} className={classes.mainContainer}>
          <Hidden smUp>
            <MobileSidebar
              countries={transformData(edges)}
              onCitySelect={handleCitySelect}
              isOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              selectedCity={selectedCityName}
            />
          </Hidden>
          <Hidden smDown>
            <Grid item md={3} className={classes.sidebarContainer}>
              <TeamSidebar
                onCitySelect={handleCitySelect}
                countries={transformData(edges)}
                selectedCity={selectedCityName}
              />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingBlock: '1rem',
                position: 'sticky',
                top: 0,
                backgroundColor: '#fff',
                width: '100dvw',
              }}
            >
              <Button onClick={toggleSidebar}>
                <Typography className={classes.filtreparPaysCTA}>
                  {intl.formatMessage({ id: 'new.Equipe_2' })}
                </Typography>
              </Button>
              <SortIcon fontSize="small" />
            </Box>
          </Hidden>
          <Grid item container md={9} xs={12} className={classes.agentList}>
            {agents ? (
              <Grid item xs={12}>
                {agents && agents.length > 0 ? (
                  <Grid container spacing={2}>
                    {agents.map((agent: any, index: number) => (
                      <Grid item xs={6} sm={4} md={3} key={index}>
                        <Avatar agent={agent} onClick={() => handleAgentSelect(agent)} hideNumber />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <p style={{ textAlign: 'center' }}>Aucun agent disponible pour cette ville.</p>
                )}
              </Grid>
            ) : (
              <img
                src={world_map}
                alt="Image"
                style={{ height: '100%', width: '100%', zIndex: 0, objectFit: 'contain' }}
              />
            )}
            {selectedAgent && (
              <AgentDialog open={dialogOpen} onClose={handleCloseDialog} agent={selectedAgent} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default AgencyTeam
